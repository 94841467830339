var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    attrs: {
      "disableScrollTop": ""
    },
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual', {
          attrs: {
            "title": "Product"
          },
          scopedSlots: _vm._u([{
            key: "customGnb",
            fn: function () {
              return [_c('product-list-search')];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }, [_c('v-container', [_vm.products.length ? [_c('v-row', {
    staticClass: "ma-n14 mx-md-n30 my-md-n24"
  }, _vm._l(_vm.products, function (item) {
    return _c('product-list-item', _vm._b({
      key: item === null || item === void 0 ? void 0 : item._id
    }, 'product-list-item', {
      item
    }, false));
  }), 1)] : [_c('v-container', [_c('v-card-title', {
    staticClass: "justify-center font-size-24 font-weight-light grey-90--text"
  }, [_vm._v(" No product has been uploaded, yet. ")])], 1)], _c('pagination-component', {
    attrs: {
      "count": _vm.pageCount,
      "value": _vm.page
    },
    on: {
      "change": function (page) {
        return _vm.$router.replace({
          query: Object.assign({}, _vm.$route.query, {
            page
          })
        });
      }
    }
  })], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }