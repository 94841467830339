var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "tab-wrap sub-tab-wrap mb-0"
  }, [_c('ul', {
    staticClass: "tab tab--inline tab--underline-inline justify-center"
  }, _vm._l(_vm.categories, function (_ref) {
    var _id = _ref._id,
      name = _ref.name,
      code = _ref.code;
    return _c('li', {
      key: _id,
      staticClass: "tab__li",
      class: {
        active: _vm.codeActive == code
      }
    }, [_c('a', {
      staticClass: "tab__btn",
      on: {
        "click": function ($event) {
          $event.stopPropagation();
          $event.preventDefault();
          return _vm.go(code);
        }
      }
    }, [_c('span', [_vm._v(" " + _vm._s(name) + " ")])])]);
  }), 0)]);

}
var staticRenderFns = []

export { render, staticRenderFns }