<template>
    <div class="tab-wrap sub-tab-wrap mb-0">
        <ul class="tab tab--inline tab--underline-inline justify-center">
            <li v-for="{ _id, name, code } in categories" :key="_id" class="tab__li" :class="{ active: codeActive == code }">
                <a class="tab__btn" @click.stop.prevent="go(code)">
                    <span> {{ name }} </span>
                </a>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    props: {
        category: String,
        categories: { type: Array, default: () => [] },
    },
    computed: {
        codeActive() {
            return this.$route.query.category;
        },
    },
    methods: {
        go(code) {
            const { ...query } = this.$route.query;
            delete query.page;

            query.category = code;
            if (this.codeActive == code) delete query.category;

            this.$router.push({ query });
        },
    },
};
</script>

<style></style>
