<template>
    <client-page disableScrollTop>
        <template v-slot:subHead>
            <sub-visual title="Product">
                <template #customGnb>
                    <product-list-search />
                </template>
            </sub-visual>
        </template>

        <v-container>
            <template v-if="products.length">
                <v-row class="ma-n14 mx-md-n30 my-md-n24">
                    <product-list-item v-for="item in products" :key="item?._id" v-bind="{ item }" />
                </v-row>
            </template>
            <template v-else>
                <v-container>
                    <v-card-title class="justify-center font-size-24 font-weight-light grey-90--text"> No product has been uploaded, yet. </v-card-title>
                </v-container>
            </template>

            <pagination-component :count="pageCount" :value="page" @change="(page) => $router.replace({ query: { ...$route.query, page } })" />
        </v-container>
    </client-page>
</template>

<script>
import api from "@/api";
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import ProductListItem from "@/components/client/products/product-list-item.vue";
import ProductListSearch from "@/components/client/products/product-list-search.vue";
import PaginationComponent from "@/components/client/control/pagination-component.vue";
import ProductListCategories from "@/components/client/products/product-list-categories.vue";
export default {
    components: {
        ClientPage,
        SubVisual,
        ProductListItem,
        ProductListSearch,
        PaginationComponent,
        ProductListCategories,
    },
    data: () => ({
        limit: 8,
        summary: { totalCount: 0 },
        products: [],
        categories: [],
    }),
    computed: {
        page() {
            return +this.$route.query.page || 1;
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            const { category, ...query } = this.$route.query;
            query.searchKey = "name";
            return { ...query };
        },
    },
    mounted() {
        this.init().then(this.syncRoute).then(this.search);
    },
    watch: {
        params() {
            this.search();
        },
    },
    methods: {
        async init() {
            const { categories } = await api.v1.shop.categories.gets();
            this.categories = categories;
        },
        async search() {
            const { skip, limit, params } = this;
            const { summary, products } = await api.v1.shop.products.gets({
                headers: { skip, limit },
                params,
            });
            this.summary = summary;
            this.products = products;
        },
    },
};
</script>

<style></style>
