var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', _vm._b({
    staticClass: "v-card--line",
    attrs: {
      "tile": "",
      "flat": ""
    }
  }, 'v-card', _vm.$attrs, false), [_vm._t("default"), _c('v-divider', {
    staticClass: "border-4"
  }), this.$slots['vCardLineContents'] ? _c('div', {
    staticClass: "v-card--line__con pt-20 pt-md-30 px-10 pb-4"
  }, [_vm._t("vCardLineContents")], 2) : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }