<template>
    <v-card tile flat v-bind="$attrs" class="v-card--line">
        <slot />
        <v-divider class="border-4" />
        <div v-if="this.$slots['vCardLineContents']" class="v-card--line__con pt-20 pt-md-30 px-10 pb-4">
            <slot name="vCardLineContents" />
        </div>
    </v-card>
</template>

<script>
export default {

};
</script>

<style scoped>
.v-card--line__con{
    font-family: var(--font-spoqa);
    text-align: center;
}
>>>.v-card--line__tit{
    font-size: 1.6rem;
    font-weight: 500;
    white-space: nowrap; overflow: hidden; text-overflow: ellipsis;
}
>>>.v-card--line__summary{
    font-size: 1.4rem;
    font-weight:300;
    line-height: 1.6;
    color: #666;
    max-height: 3.2em;
    overflow: hidden; text-overflow: ellipsis; white-space: normal; word-wrap: break-word; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical;
}
.v-card--line:hover .v-divider,
.v-card--line:hover >>>.v-card--line__tit{
    -webkit-transition: all ease-out .2s; -ms-transition: all ease-out .2s; transition: all ease-out .2s;
}
.v-card--line:hover .v-divider{
    border-color:var(--v-secondary-base);
}
.v-card--line:hover >>>.v-card--line__tit{
    color: var(--v-secondary-base);
}
@media (min-width:576px){
}
@media (min-width:768px){
    >>>.v-card--line__tit{
        font-size: 1.8rem;
    }
    >>>.v-card--line__summary{
        font-size: 1.6rem;
    }
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}
</style>
