<template>
    <v-card flat tile class="mx-auto pt-30 pb-20 pt-md-40 pb-md-30" style="max-width: 580px">
        <v-text-field v-model="searchValue" placeholder="Please enter a search term" background-color="white" outlined hide-details @keydown.enter="emit">
            <template #append-outer>
                <v-btn color="grey-6" class="v-btn--input" @click="emit"><span class="white--text">SEARCH</span></v-btn>
            </template>
        </v-text-field>
    </v-card>
</template>

<script>
const searchKeys = [
    { text: "All", value: null },
    { text: "Name", value: "name" },
    { text: "Content", value: "content" },
];
export default {
    data: () => ({
        searchValue: null,
    }),
    mounted() {
        this.sync();
    },
    watch: {
        "$route.query.searchValue"() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.searchValue = this.$route.query.searchValue || null;
        },
        emit() {
            const { ...query } = this.$route.query;

            delete query.page;

            delete query.category;

            query.searchValue = this.searchValue;
            if (!query.searchValue) delete query.searchValue;

            this.$router.push({ path: "/products", query });
        },
    },
};
</script>

<style></style>
