var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "mx-auto pt-30 pb-20 pt-md-40 pb-md-30",
    staticStyle: {
      "max-width": "580px"
    },
    attrs: {
      "flat": "",
      "tile": ""
    }
  }, [_c('v-text-field', {
    attrs: {
      "placeholder": "Please enter a search term",
      "background-color": "white",
      "outlined": "",
      "hide-details": ""
    },
    on: {
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.emit.apply(null, arguments);
      }
    },
    scopedSlots: _vm._u([{
      key: "append-outer",
      fn: function () {
        return [_c('v-btn', {
          staticClass: "v-btn--input",
          attrs: {
            "color": "grey-6"
          },
          on: {
            "click": _vm.emit
          }
        }, [_c('span', {
          staticClass: "white--text"
        }, [_vm._v("SEARCH")])])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.searchValue,
      callback: function ($$v) {
        _vm.searchValue = $$v;
      },
      expression: "searchValue"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }