<template>
    <v-col cols="6" sm="4" lg="3" class="pa-14 px-md-30 py-md-24" style="cursor: pointer" v-on="onEvents">
        <v-card-line v-bind="{ to }" class="h-100">
            <v-img :aspect-ratio="1 / 1" v-bind="{ src }">
                <v-overlay v-if="isShowsMore" absolute z-index="0" color="grey lighte--1">
                    <span class="text-h1"> More </span>
                </v-overlay>
                <v-overlay v-else-if="!src" absolute z-index="0">
                    <v-icon large>mdi-image-broken</v-icon>
                </v-overlay>
            </v-img>
        </v-card-line>
    </v-col>
</template>

<script>
import VCardLine from "@/components/client/dumb/v-card-line.vue";

export default {
    components: {
        VCardLine,
    },
    props: {
        item: { type: Object, default: () => ({}) },
    },
    computed: {
        isShowsMore() {
            return this.item.isShowsMore;
        },
        to() {
            if (this.isShowsMore) return;
            return `/products/${this.item?._id}`;
        },
        src() {
            return this.item?.thumbnail?.url;
        },
        onEvents() {
            if (this.isShowsMore) return { click: () => this.$emit("input", true) };
        },
    },
};
</script>

<style scoped>
</style>